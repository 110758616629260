require('./bootstrap');

let click = 0;
$(function () {

    if (!click)
        $(document).on('click', '[data-action="update-canvas-ranking"]', (e) => {
            click = 1;
            $('.updated').hide();
            $('.loading').show();
            const gid = $(e.currentTarget).data('gid');
            const type = $(e.currentTarget).data('type');

            const url = (type === 'ccm') ? URL_UPDATE_CANVAS_RANKING_CCM : URL_UPDATE_CANVAS_RANKING_FTM;

            $.ajax({
                url: url,
                type: 'POST',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                data: {
                    gid: gid
                },
                success: (response) => {
                    console.log(response);
                    click = 0;
                    $('.updated').show();
                    $('.loading').hide();
                    $(`[data-el="${response.gid}-${response.wid}"]`).hide();
                },
                error: (error) => {
                    console.log(error);
                    click = 0;
                    $('.updated').show();
                    $('.loading').hide();
                },

            });

        })
})